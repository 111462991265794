import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";

const apiUrl = process.env.REACT_APP_API_URL;

function PornStars() {
  const [stars, setStars] = useState([]); // State to store names
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState(""); // Search query
  const itemsPerPage = 16;

  const fetchData = async (page = 1, searchQuery = "") => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `${apiUrl}/getpostdata?page=${page}&limit=${itemsPerPage}&search=${searchQuery}`,
        { mode: "cors" }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      // Extract and flatten names, then remove duplicates by converting the array to a Set
      const uniqueNames = [...new Set(data.records.map((record) => record.name).flat())];
      setStars((prevStars) => [...prevStars, ...uniqueNames]); // Append new names to existing list
      setTotalPages(data.totalPages);
      setCurrentPage(data.currentPage);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage, search);
  }, [currentPage, search]);

  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <Helmet>
        <title>Best pornstars On | hexmy</title>
        <link rel="canonical" href="https://hexmy.com/stars" />
        <meta
          name="description"
          content="Explore bes pornstars on hexmy you an watch all pornstar videos on hexmy"
        />
      </Helmet>

      {/* Sidebar with search handling */}
      <Navbar/>
      <div style={{ width: "95%", margin: "auto", marginTop:"30px"}}>
        <h1>Porn Stars</h1>
        {loading && <p>Loading...</p>}
        {error && <p style={{ color: "red" }}>{error}</p>}
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }}>
          {/* Render all unique stars */}
          {stars.length > 0 ? (
            stars.map((name, index) => (
              <Link to={`/pornstar/${name}`} key={index} style={{ margin: "10px", textAlign: "center", textDecoration:"none", background:"#000"}}>
                <img style={{ height: "120px", width: "120px", objectFit: "cover" }} src="female.png" alt="" />
                <h2>{name}</h2>
              </Link>
            ))
          ) : (
            <p>No stars found.</p>
          )}
        </div>

        {/* Load More Button */}
        {currentPage < totalPages && (
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <button
              className="btn btn-dark"
              onClick={handleLoadMore}
              disabled={loading}
            >
              {loading ? "Loading..." : "Load More"}
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default PornStars;
